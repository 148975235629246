<template>
    <div class="container">
        <div class="header_common_box">
            <v-header :params="{ title, leftIcon: true}"></v-header>
        </div>
        <div class="content">
            <h2>收益概述</h2>
            <div class="list">
                <div class="list-item">
                    <p class="title">1、<span>总收益？</span>
                        </p>
                    <p>通过彩友打赏、买心水所获得的收益总和。</p>
                </div>
                <div class="list-item">
                    <p class="title">2、<span>兑换审批中？</span>
                        </p>
                    <p>收益是可以兑换的，实名认证审核通过后，就可以兑换收益，提交收益后，需要管理员审批，审批通过后才能到账，在此期间审批的收益，叫作：兑换审批中</p>
                </div>
                <div class="list-item">
                    <p class="title">3、<span>已累计兑换</span>
                        </p>
                    <p>成功累计兑换的收益，叫作：已累计兑换</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import vHeader from "@/components/header.vue";
    import isWeixin from '@/utils/utils.js'
    
    export default {
        data(){
            return{
                title:this.$route.meta.title
            }
        },
        components:{
            vHeader
        },
        mounted:function(){
        //判断网页是什么打开
            let isWeiXin = isWeixin()
            if(isWeiXin){
            this.showHeader = false
            }
            if(this.showHeader==false){
            document.title = ''
            }
        },
    }
</script>
<style lang="less" scoped>
    .container{
        width: 100%;
        height: 100%;
        .content{
            h2{
                text-align: center;
                margin: 20px;
            }
            .list{
                display: flex;
                flex-direction: column;
                width: 88%;
                margin: 0 auto;
                font-size: 14px;
                .list-item{
                    width: 100%;
                    .title{
                        span{
                            color: red;
                        }
                    }
                }
            }
        }
    }
</style>